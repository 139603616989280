// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
	margin: 0;
	padding: 0;
	height: 100%;
	box-sizing: border-box;
	overflow: hidden;
}

.ql-bubble.ql-container {
	background: rgba(0, 0, 0, 0.111);
}
::-webkit-scrollbar {
	display: none;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}
`, "",{"version":3,"sources":["webpack://./src/Styles/styles.css"],"names":[],"mappings":"AAAA;;CAEC,SAAS;CACT,UAAU;CACV,YAAY;CACZ,sBAAsB;CACtB,gBAAgB;AACjB;;AAEA;CACC,gCAAgC;AACjC;AACA;CACC,aAAa;AACd;;AAEA;;;CAGC,mBAAmB;AACpB","sourcesContent":["html,\nbody {\n\tmargin: 0;\n\tpadding: 0;\n\theight: 100%;\n\tbox-sizing: border-box;\n\toverflow: hidden;\n}\n\n.ql-bubble.ql-container {\n\tbackground: rgba(0, 0, 0, 0.111);\n}\n::-webkit-scrollbar {\n\tdisplay: none;\n}\n\n*,\n*::before,\n*::after {\n\tbox-sizing: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
